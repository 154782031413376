<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("purchaseOrder.purchaseOrderList") }}</span
        >
        <CommonAdd
          v-if="accessRight.includes('create')"
          :name="$t('route.purchaseOrder')"
          pathName="addPurchaseOrder"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else-if="purchaseOrderList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="purchaseOrderList.data"
              :items-per-page="limit"
              :page.sync="page"
              :server-items-length="purchaseOrderList.meta.total"
              style="word-break: break-word"
              :options.sync="pagination"
              item-class="py-4"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
              @update:sort-by="(val) => buildUrl(val, 'sortKey')"
              @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
            >
              <template v-slot:top>
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="2" class="text-end d-flex">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        v-model="selectedStatus"
                        :items="statusList"
                        item-text="name"
                        item-value="id"
                        flat
                        @input="buildUrl"
                        return-object
                        single-line
                        :label="$t('order.field.status')"
                      />
                    </v-col>
                    <v-col cols="3"> </v-col>
                    <v-col cols="2" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        @input="buildUrl"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        hide-details="auto"
                        dense
                        v-model="selectedSupplier"
                        :items="supplierList.data"
                        item-text="name_1"
                        item-value="id"
                        clearable
                        flat
                        return-object
                        single-line
                        @input="buildUrl"
                        :search-input.sync="supplierSearch"
                        :label="$t('purchaseOrder.supplier')"
                      >
                        <template v-slot:append-item>
                          <div
                            v-show="hasSupplierNextPage"
                            v-intersect="infiniteScroll"
                            ref="load"
                            class="loader text-center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            />
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="text-end d-flex">
                      <v-menu
                        ref="startDateMenu"
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="order_date"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="40"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            class="me-2"
                            single-line
                            v-model="orderDateFormat"
                            :label="$t('order.from')"
                            @click:clear="order_date = ''"
                            readonly
                            @input="buildUrl"
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="order_date"
                          @input="buildUrl"
                          @change="$refs.startDateMenu.save(order_date)"
                        >
                          <v-btn
                            small
                            class="primary"
                            @click="$refs.startDateMenu.save(todayDate())"
                            >{{ $t("route.today") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="endDateMenu"
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="order_validity"
                        transition="scale-transition"
                        offset-y
                        :nudge-left="160"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details="auto"
                            dense
                            single-line
                            v-model="orderValideDateFormat"
                            :label="$t('order.to')"
                            @click:clear="order_validity = ''"
                            readonly
                            @input="buildUrl"
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :min="order_date ? order_date : ''"
                          v-model="order_validity"
                          @input="buildUrl"
                          @change="$refs.endDateMenu.save(order_validity)"
                        >
                          <v-btn
                            small
                            class="primary"
                            @click="$refs.endDateMenu.save(todayDate())"
                            >{{ $t("route.today") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.grand_total`]="props">
                <span>{{ numberWithCommas(props.item.grand_total) }}</span>
              </template>
              <template v-slot:[`item.order_date`]="props">
                <span>{{
                  props.item.order_date
                    ? profileById.date_format
                      ? new Date(props.item.order_date)
                          .toISOString()
                          .substring(0, 10)
                      : formatDateDDMMYYYY(props.item.order_date)
                    : ""
                }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                <v-chip :color="getColor(props.item.status)" small dark>
                  {{
                    statusList.filter((x) => x.id == props.item.status)[0].name
                  }}
                </v-chip>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2"
                      >{{ $t("common.numberOfTotalRows") }}
                      {{ purchaseOrderList.meta.total }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="purchaseOrderList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else>
            {{ $t("common.noDataFound") }}</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import CommonAdd from "@/components/CommonAdd";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "PurchaseOrder",
  components: {
    ListSkeleton,
    CommonAdd,
  },
  mixins: [intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      supplierPage: 1,
      supplierLimit: 10,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "order_date"],
        sortDesc: this.$route.query.order_by
          ? this.$route.query.sortDec
            ? [true]
            : [false]
          : this.$route.query.sortDec
          ? [true]
          : [false],
      },
      selectedSupplier: {
        id: Number(this.$route.query.suppId) || null,
      },
      search: this.$route.query.search || "",
      listSkeleton: false,
      startDateMenu: false,
      endDateMenu: false,
      order_date:
        this.$route.query.startDate ||
        new Date(new Date().setMonth(new Date().getMonth() - 6))
          .toISOString()
          .substring(0, 10),
      order_validity:
        this.$route.query.endDate || new Date().toISOString().substring(0, 10),
      selectedStatus: {
        id:
          this.$route.query.status === "all"
            ? null
            : this.$route.query.status === "closed"
            ? 1
            : this.$route.query.status === "cancelled"
            ? 2
            : (this.$route.query.status === "open" && 0) || 0,
      },
    };
  },
  computed: {
    orderDateFormat() {
      return this.profileById.date_format
        ? this.order_date
        : this.formatDateDDMMYYYY(this.order_date);
    },
    orderValideDateFormat() {
      return this.profileById.date_format
        ? this.order_validity
        : this.formatDateDDMMYYYY(this.order_validity);
    },
    statusList() {
      return [
        { name: this.$t("order.field.all"), id: null },
        { name: this.$t("order.field.open"), id: 0 },
        { name: this.$t("order.field.finished"), id: 1 },
        { name: this.$t("order.field.canceled"), id: 2 },
      ];
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        supplier_id: this.selectedSupplier?.id,
        selectedStatus: this.selectedStatus?.id,
        order_date: this.order_date,
        order_validity: this.order_validity,
      };
    },
    ...mapGetters({
      purchaseOrderList: "purchaseOrder/purchaseOrderList",
      supplierList: "supplier/supplierList",
      accessRight: "accessRight",
      profileById: "profile/profileById",
    }),
    headers() {
      return [
        {
          text: this.$t("order.id"),
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("purchaseOrder.purchaseOrderNumber"),
          value: "title",
          width: "260px",
        },
        {
          text: this.$t("purchaseOrder.supplier"),
          value: "supplier.name_1",
          sortable: false,
          width: "260px",
        },
        this.accessRight.includes("financial")
          ? { text: this.$t("order.field.grandTotal"), value: "grand_total" }
          : "",
        { text: this.$t("order.field.orderStartDate"), value: "order_date" },
        {
          text: this.$t("order.field.status"),
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    orderDateFormat() {
      this.order_date = this.profileById.date_format
        ? this.orderDateFormat
        : this.formatDateDDMMYYYY(this.orderDateFormat);
    },
    orderValideDateFormat() {
      this.order_validity = this.profileById.date_format
        ? this.orderValideDateFormat
        : this.formatDateDDMMYYYY(this.orderValideDateFormat);
    },
    supplierLimit() {
      this.$store.dispatch("supplier/GetSupplier", {
        page: 1,
        limit: this.supplierLimit,
        order_by: "name_1|asc",
      });
    },
    params: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.paginate(false);
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.page = Number(this.$route.query.page) || 1;

        this.sortByValue = this.$route.query.order_by || null;

        this.sortDescValue = this.$route.query.sortDec ? true : null;

        this.selectedSupplier = {
          id: Number(this.$route.query.suppId) || null,
        };

        this.search = this.$route.query.search || "";

        this.order_date =
          this.$route.query.startDate ||
          new Date(new Date().setMonth(new Date().getMonth() - 6))
            .toISOString()
            .substring(0, 10);

        this.order_validity =
          this.$route.query.endDate ||
          new Date().toISOString().substring(0, 10);

        this.selectedStatus = {
          id:
            this.$route.query.status === "all"
              ? null
              : this.$route.query.status === "closed"
              ? 1
              : this.$route.query.status === "cancelled"
              ? 2
              : (this.$route.query.status === "open" && 0) || 0,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit(
      "purchaseOrder/SET_PURCHASE_ORDER_QUERY",
      this.$route.query
    );
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["order_date"];
      this.pagination.sortDesc = [true];
    }
    this.$store.dispatch("profile/GetCompanyById");
    this.$store.dispatch("supplier/GetSupplier", {
      page: this.supplierPage,
      limit: this.supplierLimit,
      order_by: "name_1|asc",
    });
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    getColor(status) {
      if (status == 0) return "orange";
      else if (status == 1) return "green";
      else return "red";
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        supplier_id,
        selectedStatus,
        order_date,
        order_validity,
      } = this.params;
      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      concateWhereAnd += order_date ? "from_order_date," : "";
      concateWhereAnd += order_validity ? "to_order_date," : "";
      concateWhereAnd += selectedStatus || selectedStatus == 0 ? "status," : "";
      whereAndVal += order_date
        ? this.profileById.date_format
          ? this.parseDateYYYYMMDD(order_date) + ","
          : order_date + ","
        : "";
      whereAndVal += order_validity
        ? this.profileById.date_format
          ? this.parseDateYYYYMMDD(order_validity) + ","
          : order_validity + ","
        : "";
      whereAndVal +=
        selectedStatus || selectedStatus == 0 ? selectedStatus + "," : "";
      // end make params for where_and
      await this.$store
        .dispatch("purchaseOrder/GetPurchaseOrder", {
          page: isSearch ? 1 : page,
          limit: itemsPerPage,
          where: supplier_id ? "supplier_id|" + supplier_id : supplier_id,
          where_and:
            concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
              ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
              : undefined,
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : "id,title,grand_total,order_date|" + this.search
            : "id,title,grand_total,order_date|",
          order_by: sortBy?.[0]
            ? sortBy?.[0] + ",id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc"
            : "order_date,id|" + (sortDesc?.[0] ? "asc" : "desc") + ",desc",
        })
        .then(() => (this.listSkeleton = false));
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        this.page = 1;
        obj.page = 1;
        this.params.page = 1;
      }
      obj.search = this.search;
      obj.page = this.page;
      obj.status = this.selectedStatus?.id;
      obj.suppId = this.selectedSupplier?.id;
      obj.startDate = this.order_date;
      obj.endDate = this.order_validity;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "suppId" && val === null) return acc;
        else if (key === "page" && val === 1) return acc;
        else if (key === "startDate" && val === null) return acc;
        else if (key === "endDate" && val === null) return acc;
        else if (key === "status") {
          if (val === null) {
            return { ...acc, [key]: "all" };
          } else if (val === 1) {
            return { ...acc, [key]: "closed" };
          } else if (val === 2) {
            return { ...acc, [key]: "cancelled" };
          } else if (val === 0) {
            return { ...acc, [key]: "open" };
          } else return acc;
        } else if (key !== "status" && !val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router
        .replace({
          name: "PurchaseOrder",
          query: {
            ...obj,
          },
        })
        .catch(() => {});
      this.$store.commit("purchaseOrder/SET_PURCHASE_ORDER_QUERY", obj);
    },
    redirectOnEditPage(event, { item }) {
      const path = "purchaseOrder/purchaseOrderAction/" + item.id;
      const windowName = "purchaseOrderTab_" + item.id;
      if (
        this.accessRight.includes("edit") ||
        this.accessRight.includes("show")
      ) {
        window.open(path, windowName);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>
