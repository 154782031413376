<template>
  <div>
    <div v-if="this.$route.name === 'PurchaseOrder'">
      <PurchaseOrder />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import PurchaseOrder from "@/views/companyApp/purchaseOrder/PurchaseOrder.vue";

export default {
  name: 'PurchaseOrderParent',
  components: {
    PurchaseOrder
  }
}
</script>
